class QauntitySelector {
    constructor(selector) {
        this.increaseQty = this.increaseQty.bind(this)
        this.decreaseQty = this.decreaseQty.bind(this)
        this.handleQuantity = this.handleQuantity.bind(this)
        this.plusButton = selector.querySelector('.js-qty-plus')
        this.minusButton = selector.querySelector('.js-qty-minus')
        this.inputField = selector.querySelector('.js-qty-input')
        this.currentQty = parseInt(this.inputField.value)
        this.init();
    }
    clampQty(num, max = Number.MAX_SAFE_INTEGER) {
        return Math.min(Math.max(num, 1), max);
    }

    init() {
        console.log(`quantity ${this.currentQty}`)
        this.plusButton.addEventListener('click', this.increaseQty)
        this.minusButton.addEventListener('click', this.decreaseQty)
    }
    
    increaseQty() {
        this.currentQty += 1;
        this.inputField.value = this.currentQty
        this.handleQuantity()
    }
    decreaseQty() {
        this.currentQty = this.clampQty(this.currentQty - 1);
        this.inputField.value = this.currentQty
        this.handleQuantity()
    }
    handleQuantity() {
        console.log(this.inputField)
        this.inputField.classList.add('changed')
        setTimeout(() => {
            this.inputField.classList.remove('changed')
        },
        300)

    }
}

export default QauntitySelector