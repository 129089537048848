class Cart {
    constructor() {
        // this.addToCart = this.addToCart.bind(this)    
        this.init();
    }

    addToCart(event) {
        event.preventDefault()
    }

    init() {
        // const items = document.querySelectorAll('.js-add-to-cart')
        
        // items.forEach((item) => {
        //     item.addEventListener('click', (event) => {
        //         event.preventDefault();
        //         this.addToCart(event)
        //         return false
        //     })
        // })


        this.attachFormSubmit();

    }
    handleFormSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form)
        console.log('Submit', formData)
    }
    attachFormSubmit() {
        const forms = document.querySelectorAll('.js-add-to-cart-form')

        forms.forEach(form => {
            form.addEventListener('submit', this.handleFormSubmit)
        })
    }
}

export default Cart;