class Accordion {
    constructor() {
        console.log('asdasd');
    }

    init() {
        const accordions = document.querySelectorAll('.js-accordion-handle');

        accordions.forEach((element) => {
            element.addEventListener('click', (event) => {
                const handler = event.target;
                const content = handler.nextElementSibling;
                const openState = (content.getAttribute('aria-expanded') === 'true') ? true : false ;

                handler.classList.toggle('expanded', !openState);
                content.setAttribute('aria-expanded', !openState);
            })
        })
    }
}

export default Accordion;