import "./styles.scss";
import Accordion from './js/modules/accordion';
import Cart from "./js/modules/cart";
import QauntitySelector from "./js/modules/quantity-select"

const acc = new Accordion();
acc.init();

const cart = new Cart();

document.querySelectorAll('.js-qty-selector').forEach(selector => {
    new QauntitySelector(selector)
})
